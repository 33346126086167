import _ from 'lodash';
import * as controllers from './controllers';
import * as menus from './menus';
import * as tpa from './tpa';
import * as constants from '../constants';
import { SOSP_COMP_RESPONSIVE_COMP_DEF } from '../constants/responsive-sosp-layouts';
import { LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF } from '../constants/responsive-login-bar-layouts';
import {
  MEMBERS_MENU_RESPONSIVE_APP_WIDGET,
  MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL,
} from '../constants/responsive-menu-layouts';
import { getSiteLocale, isRtlLocale } from '../../utils/locale';
import { log } from '../../utils/monitoring';
import { areAppWidgetsEnabled, isADIHorizontalLayoutEnabled, isCustomProfileEnabled } from '../../utils/experiments';
import { getIsADI, getIsResponsiveEditor, getStaticsBaseUrl } from '../services/applicationState.ts';
import i18next from '../../i18n';
import { setProfileCardBooleanParam } from '../services/members-area';
import { LOGIN_COMP_APP_WIDGET_DEF, LOGIN_COMP_APP_WIDGET_DEF_ECOM } from '../constants/classic-login-bar-layouts';

const { APP_TOKEN } = constants;

async function addProfileWidget(editorSDK, sospContainer) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const allowHorizontalInADI = await isADIHorizontalLayoutEnabled();
  const isHorizontalLayout = !getIsADI() || allowHorizontalInADI;
  const { appDefinitionId } = constants.PROFILE_WIDGET_APP;
  const isAlreadyInstalled = await tpa.isApplicationInstalled({ editorSDK, appDefinitionId });

  // Remove existing components for unexpected cases when they are already installed in the site
  if (isAlreadyInstalled) {
    const appData = await tpa.getDataByAppDefId({ editorSDK, appDefinitionId });
    if (appData?.applicationId) {
      const components = await tpa.getAllCompsByApplicationId({ editorSDK, applicationId: appData.applicationId });
      if (components && components.length > 0) {
        const componentsRefs = await getComponentsRefsByIds({ editorSDK, ids: components.map((c) => c.id) });
        await deleteComponents({ editorSDK, componentsRefs });
      }
    } else {
      log('Invalid getDataByAppDefId', { extra: { appDefinitionId, source: 'addProfileWidget' } });
    }
  }

  await editorSDK.tpa.add.application(APP_TOKEN, {
    appDefinitionId,
    ...(isHorizontalLayout ? constants.PW_HORIZONTAL_LAYOUT : constants.PW_VERTICAL_LAYOUT),
    containerRef: sospContainer,
    cancelSave: true,
  });

  // For some reason initially defined width from dev center does not change if we provide another value, so we resize after installation
  // This can most likely be removed when dev center width value changes from 250 to 980
  try {
    if (isHorizontalLayout) {
      const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
        APP_TOKEN,
        constants.PROFILE_WIDGET_APP.appDefinitionId,
      );
      const profileWidgetComponents = await editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
      const installedComponentRef = await editorSDK.components.getById(APP_TOKEN, {
        id: profileWidgetComponents[0].id,
      });
      await editorSDK.components.layout.update(APP_TOKEN, {
        componentRef: installedComponentRef,
        layout: constants.PW_HORIZONTAL_LAYOUT,
      });
    }
  } catch (e) {
    log('An error occured when resizing PW to horizontal layout, the MA installation will most likely look broken', {
      tags: { error: e.toString() },
    });
  }

  if (isResponsiveEditor) {
    const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId(
      APP_TOKEN,
      constants.PROFILE_WIDGET_APP.appDefinitionId,
    );
    const components = await editorSDK.tpa.app.getAllCompsByApplicationId(APP_TOKEN, applicationId);
    const compId = components[0].id;
    const componentRef = await editorSDK.components.getById(APP_TOKEN, { id: compId });
    await editorSDK.document.responsiveLayout.update(APP_TOKEN, {
      componentRef,
      responsiveLayout: constants.PW_RESPONSIVE_LAYOUT,
    });
  }

  // Clean this after specs.membersArea.customProfile is merged
  try {
    const enableCustomProfile = await isCustomProfileEnabled();
    if (enableCustomProfile) {
      await setProfileCardBooleanParam(editorSDK, constants.ENABLE_CUSTOM_PROFILE_BOOLEAN_SETTING);
    }
  } catch (e) {
    log('Failed to set Custom Profile boolean param for Profile card during the installation');
  }
}

async function addLoginButton(editorSDK, controllerRef, headerRef) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const shouldInstallAppWidgets = isResponsiveEditor || (await areAppWidgetsEnabled());
  const isStoreInstalled = await editorSDK.tpa.isApplicationInstalled(APP_TOKEN, {
    appDefinitionId: constants.ECOM_APP_DEF_ID,
  });
  const compDef = isStoreInstalled ? constants.LOGIN_COMP_DEF_ECOM : constants.LOGIN_COMP_DEF;
  const defaultLanguagePromise = editorSDK.info.getLanguage();
  const headerLayout = await editorSDK.components.layout.get(APP_TOKEN, { componentRef: headerRef });
  compDef.layout.y = (headerLayout.height - compDef.layout.height) / 2;
  compDef.data.language = await defaultLanguagePromise;

  let loginRef;

  if (shouldInstallAppWidgets) {
    const appWidgetCompDef = isResponsiveEditor
      ? LOGIN_COMP_RESPONSIVE_APP_WIDGET_DEF
      : isStoreInstalled
      ? LOGIN_COMP_APP_WIDGET_DEF_ECOM
      : LOGIN_COMP_APP_WIDGET_DEF;

    const appWidgetRef = await editorSDK.components.add(APP_TOKEN, {
      pageRef: headerRef,
      componentDefinition: appWidgetCompDef,
      showAddToHeaderPanel: false,
    });

    loginRef = (await editorSDK.document.components.getChildren(APP_TOKEN, { componentRef: appWidgetRef }))[0];
  } else {
    loginRef = await editorSDK.components.add(APP_TOKEN, {
      pageRef: headerRef,
      componentDefinition: isResponsiveEditor ? compDef : { ...compDef, layoutResponsive: undefined },
      showAddToHeaderPanel: false,
    });
  }

  await connectLoginButton(editorSDK, loginRef, controllerRef);
}

async function connectLoginButton(editorSDK, loginRef, controllerRef) {
  const shouldInstallAppWidgets = getIsResponsiveEditor() || (await areAppWidgetsEnabled());
  const menusIds = menus.getMenuIds();

  const dataToUpdate = {
    menuItemsRef: {
      menuRef: '#' + menusIds.login,
      type: 'CustomMenuDataRef',
    },
    iconItemsRef: {
      menuRef: '#' + menusIds.icons,
      type: 'CustomMenuDataRef',
    },
  };

  editorSDK.components.data.update(APP_TOKEN, { componentRef: loginRef, data: dataToUpdate });

  if (!shouldInstallAppWidgets) {
    await controllers.connectToController({
      editorSDK,
      controllerRef,
      connectToRef: loginRef,
      role: 'members_login',
    });
  }
}

async function addSubPagesMenu(editorSDK, menuId, sospContainer, controllerRef, isHorizontal = false) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const allowHorizontalInADI = await isADIHorizontalLayoutEnabled();
  const shouldInstallHorizontalMenu = (!getIsADI() || allowHorizontalInADI) && isHorizontal;
  const isHorizontalLayout = isResponsiveEditor || shouldInstallHorizontalMenu;
  const shouldInstallAppWidgets = isResponsiveEditor || (await areAppWidgetsEnabled());
  const locale = await getSiteLocale(editorSDK);
  const shouldInstallRtlLocale = isRtlLocale(locale);
  const i18n = await i18next(getStaticsBaseUrl(), locale);

  if (shouldInstallAppWidgets) {
    let appWidgetDef;
    if (isResponsiveEditor) {
      appWidgetDef = shouldInstallRtlLocale
        ? MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL
        : MEMBERS_MENU_RESPONSIVE_APP_WIDGET;
      appWidgetDef.components[0].props.moreButtonLabel = i18n.t('Members_Subpages_Horizontal_Menu');
    } else {
      // Need to implement support for app widget in classic editor
      appWidgetDef = shouldInstallRtlLocale
        ? MEMBERS_MENU_RESPONSIVE_APP_WIDGET_RTL
        : MEMBERS_MENU_RESPONSIVE_APP_WIDGET;
      appWidgetDef.components[0].props.moreButtonLabel = i18n.t('Members_Subpages_Horizontal_Menu');
    }

    const appWidgetRef = await editorSDK.components.add(APP_TOKEN, {
      pageRef: sospContainer,
      componentDefinition: appWidgetDef,
      showAddToHeaderPanel: false,
    });
    const menuRef = (await editorSDK.document.components.getChildren(APP_TOKEN, { componentRef: appWidgetRef }))[0];
    return await connectMenu(editorSDK, menuRef, menuId);
  }

  let compDef;
  if (isHorizontalLayout) {
    compDef = shouldInstallRtlLocale ? constants.MENU_COMP_DEF_HORIZONTAL_RTL : constants.MENU_COMP_DEF_HORIZONTAL;
    compDef.props.moreButtonLabel = i18n.t('Members_Subpages_Horizontal_Menu');
  } else {
    compDef = shouldInstallRtlLocale ? constants.MENU_COMP_DEF_RTL : constants.MENU_COMP_DEF;
  }

  const menuRef = await editorSDK.components.add(APP_TOKEN, {
    pageRef: sospContainer,
    componentDefinition: compDef,
    showAddToHeaderPanel: false,
  });

  await Promise.all([
    connectControllerToMenu(editorSDK, menuRef, controllerRef),
    connectMenu(editorSDK, menuRef, menuId),
  ]);
}

async function connectControllerToMenu(editorSDK, menuRef, controllerRef) {
  // TODO use connectSubPagesMenu function, it's not working now because there are no connected components at that moment in getMenuIds
  await controllers.connectToController({
    editorSDK,
    controllerRef,
    connectToRef: menuRef,
    role: 'members_menu',
  });
}

async function connectMenu(editorSDK, menuRef, menuId) {
  await editorSDK.menu.connect(APP_TOKEN, { menuCompPointer: menuRef, menuId });
}

async function connectSubPagesMenu(editorSDK, menuRef, controllerRef) {
  const menusIds = menus.getMenuIds();

  await Promise.all([
    connectControllerToMenu(editorSDK, menuRef, controllerRef),
    connectMenu(editorSDK, menuRef, menusIds.members),
  ]);
}

async function createSospContainer(editorSDK, headerRef, masterRef) {
  const isResponsiveEditor = getIsResponsiveEditor();
  const allowHorizontalInADI = await isADIHorizontalLayoutEnabled();
  const isHorizontalLayout = !getIsADI() || allowHorizontalInADI;
  let sospContainerId = constants.SOSP_CONTAINER_CUSTOM_ID;
  const allComponents = await editorSDK.components.getAllComponents();
  const sospContainer = allComponents.find((comp) => comp.id === sospContainerId);
  if (sospContainer) {
    log('SOSP container already existing during the installation');
    const ch = await editorSDK.components.getChildren(APP_TOKEN, { componentRef: sospContainer });
    await Promise.all(ch.map((c) => editorSDK.components.remove(APP_TOKEN, c)));
    // can not remove SOSP - editor throws "can not delete a non displayed component" error for sites with broken installations.
    // so adding a SOSP with id += '_1'
    await removeSospContainer(editorSDK).catch(() => log('Can not remove members SOSP container'));
    sospContainerId += '_1';
  }

  if (isResponsiveEditor) {
    const responsiveContainerDef = _.cloneDeep(SOSP_COMP_RESPONSIVE_COMP_DEF);
    const headerLayout = await editorSDK.components.layout.get(APP_TOKEN, { componentRef: headerRef });
    responsiveContainerDef.layout.y += headerLayout.height;
    return await editorSDK.components.add(APP_TOKEN, {
      pageRef: masterRef,
      componentDefinition: responsiveContainerDef,
      showAddToHeaderPanel: false,
      customId: sospContainerId,
    });
  }

  const containerDef = _.cloneDeep(isHorizontalLayout ? constants.SOSP_CONTAINER_HORIZONTAL : constants.SOSP_CONTAINER);
  const headerLayout = await editorSDK.components.layout.get(APP_TOKEN, { componentRef: headerRef });
  containerDef.layout.y += headerLayout.height;
  return await editorSDK.components.add(APP_TOKEN, {
    pageRef: masterRef,
    componentDefinition: containerDef,
    showAddToHeaderPanel: false,
    customId: sospContainerId,
  });
}

async function handleCompAddedToStage(editorSDK, compRef) {
  // TODO decide component to connect according to comp type after WEED-3513 is resolved and not by compData
  const isAppController =
    (await editorSDK.components.data.get(APP_TOKEN, { componentRef: compRef })).type === 'AppController';
  let controllerRefPromise;
  let componentRef;

  if (isAppController) {
    controllerRefPromise = Promise.resolve(compRef);
    componentRef = (await editorSDK.controllers.getControllerConnections('', { controllerRef: compRef }))[0]
      .componentRef;
  } else {
    controllerRefPromise = controllers.getController(editorSDK);
    componentRef = compRef;
  }

  const compData = await editorSDK.components.data.get(APP_TOKEN, { componentRef });
  const locale = await getSiteLocale(editorSDK);

  await editorSDK.components.properties.update(APP_TOKEN, {
    componentRef,
    props: { itemsAlignment: isRtlLocale(locale) ? 'right' : 'left' },
  });

  if (compData.type === 'LoginSocialBar') {
    await connectLoginButton(editorSDK, componentRef, await controllerRefPromise);
  } else if ((compData.type === 'MenuDataRef' || compData.type === 'CustomMenuDataRef') && !isAppController) {
    await connectSubPagesMenu(editorSDK, componentRef, await controllerRefPromise);
  }
}

async function removeSospContainer(editorSDK) {
  const sospRef = await getSOSPContainerRef(editorSDK);
  if (sospRef) {
    await editorSDK.components.remove(APP_TOKEN, { componentRef: sospRef });
  }
}

async function getSOSPContainerRef(editorSDK) {
  const sospRef = await editorSDK.components.getById(APP_TOKEN, { id: constants.SOSP_CONTAINER_CUSTOM_ID });

  if (sospRef) {
    return sospRef;
  }

  const sospRefFallback = await editorSDK.components.getById(APP_TOKEN, {
    id: `${constants.SOSP_CONTAINER_CUSTOM_ID}_1`,
  });
  return sospRefFallback;
}

async function getComponentsRefsByIds({ editorSDK, ids }) {
  const compRefGetter = async (id) => await editorSDK.components.getById(APP_TOKEN, { id });
  return await Promise.all(ids.map(compRefGetter));
}

async function deleteComponents({ editorSDK, componentsRefs }) {
  return await Promise.all(
    componentsRefs.map((componentRef) => editorSDK.components.remove(APP_TOKEN, { componentRef })),
  );
}

function getComponentLayout({ editorSDK, componentRef }) {
  return editorSDK.components.layout.get('', { componentRef });
}

function updateComponentLayout({ editorSDK, componentRef, layout }) {
  return editorSDK.document.components.layout.update('', { componentRef, layout });
}

function removeComponent({ editorSDK, componentRef }) {
  return editorSDK.document.components.remove('', { componentRef });
}

function getComponentChildren({ editorSDK, componentRef }) {
  return editorSDK.document.components.getChildren('', { componentRef });
}

async function getAllComponentChildren({ editorSDK, parentComponentRef }) {
  const childrenRefs = await editorSDK.document.components.getChildren(APP_TOKEN, { componentRef: parentComponentRef });
  const descendantsRefs = await Promise.all(
    childrenRefs.map((ref) => getAllComponentChildren({ editorSDK, parentComponentRef: ref })),
  );

  return _.flatten(childrenRefs.concat(descendantsRefs));
}

function getComponentData({ editorSDK, componentRef }) {
  return editorSDK.document.components.data.get('', { componentRef });
}

async function getSOSPProfileCardComponentRef({ editorSDK }) {
  const sospContainerRef = await getSOSPContainerRef(editorSDK);
  const sospChildren = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const sospChildrenDatas = await Promise.all(
    sospChildren.map((componentRef) => getComponentData({ editorSDK, componentRef })),
  );

  const pwData = sospChildrenDatas.find(
    (data) => data.appDefinitionId === constants.PROFILE_WIDGET_APP.appDefinitionId,
  );
  const pwComponentIndex = sospChildrenDatas.indexOf(pwData);
  return sospChildren[pwComponentIndex];
}

async function getMenuInSOSPCompRef({ editorSDK, sospContainerRef }) {
  const sospChildren = await getComponentChildren({ editorSDK, componentRef: sospContainerRef });
  const sospChildrenDatas = await Promise.all(
    sospChildren.map((componentRef) => getComponentData({ editorSDK, componentRef })),
  );
  const menuComponentData = sospChildrenDatas.find((data) => data.menuRef === `#${constants.MENU_IDS.SUB_MENU_ID}`);
  const menuComponentIndex = sospChildrenDatas.indexOf(menuComponentData);
  const menuComponentRef = sospChildren[menuComponentIndex];
  return menuComponentRef;
}

async function fixSOSPHeightForVerticalLayout({ editorSDK, pwComponentRef, sospContainerRef, menuRef }) {
  const populatedPwCompRef = pwComponentRef || (await getSOSPProfileCardComponentRef({ editorSDK }));
  const populatedSospRef = sospContainerRef || (await getSOSPContainerRef(editorSDK));
  const populatedMenuRef = menuRef || (await getMenuInSOSPCompRef({ editorSDK, sospContainerRef: populatedSospRef }));

  if (!populatedMenuRef || !populatedSospRef || !populatedPwCompRef) {
    return;
  }

  const pwLayout = await getComponentLayout({ editorSDK, componentRef: populatedPwCompRef });
  const menuLayout = await getComponentLayout({ editorSDK, componentRef: populatedMenuRef });
  const menuY = pwLayout.y + pwLayout.height + constants.PW_SIDEBAR_SOSP_BOTTOM_MARGIN;
  const sospHeight =
    pwLayout.height +
    menuLayout.height +
    constants.PW_SIDEBAR_SOSP_TOP_MARGIN +
    2 * constants.PW_SIDEBAR_SOSP_BOTTOM_MARGIN;

  await updateComponentLayout({ editorSDK, componentRef: populatedSospRef, layout: { height: sospHeight } });
  await updateComponentLayout({ editorSDK, componentRef: populatedMenuRef, layout: { y: menuY } });
}

function getById({ editorSDK, id }) {
  return editorSDK.document.components.getById('', { id });
}

function updateFullStyle({ editorSDK, componentRef, style }) {
  return editorSDK.document.components.style.updateFull('', { componentRef, style });
}

function updateProperties({ editorSDK, pageRef, props }) {
  return editorSDK.components.properties.update('', {
    componentRef: { id: pageRef.id, type: pageRef.type },
    props,
  });
}

async function getComponentType({ editorSDK, componentRef }) {
  return editorSDK.components.getType(APP_TOKEN, { componentRef });
}

async function removeComponentsByAppDefIds(editorSDK, appDefIds) {
  const allComponents = await editorSDK.components.getAllComponents();
  for (const componentRef of allComponents) {
    const componentData = await getComponentData({ editorSDK, componentRef });
    if (!componentData) {
      continue;
    }

    if (appDefIds.indexOf(componentData.appDefinitionId) !== -1) {
      await removeComponent({ editorSDK, componentRef });
    }
  }
}

async function isLoginBarComponentAdded({ editorSDK }) {
  const header = await editorSDK.siteSegments.getHeader();
  const headerChildren = await getAllComponentChildren({ editorSDK, parentComponentRef: header });
  const childrenTypesPromises = headerChildren.map((childRef) =>
    getComponentType({ editorSDK, componentRef: childRef }),
  );
  const childrenTypes = await Promise.all(childrenTypesPromises);
  const hasLoginComponent = childrenTypes.includes('wysiwyg.viewer.components.LoginSocialBar');
  return hasLoginComponent;
}

export {
  addLoginButton,
  addProfileWidget,
  addSubPagesMenu,
  createSospContainer,
  handleCompAddedToStage,
  removeSospContainer,
  getSOSPContainerRef,
  removeComponent,
  updateComponentLayout,
  getComponentLayout,
  getComponentChildren,
  getAllComponentChildren,
  getComponentData,
  getMenuInSOSPCompRef,
  getSOSPProfileCardComponentRef,
  fixSOSPHeightForVerticalLayout,
  getById,
  updateFullStyle,
  updateProperties,
  getComponentType,
  removeComponentsByAppDefIds,
  isLoginBarComponentAdded,
};
