import { IntegrationApplication } from '@wix/members-area-integration-kit';
import { EditorSDK } from '@wix/platform-editor-sdk';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';

import * as experiments from '../../utils/experiments';
import { MY_ACCOUNT_APP_DEF_ID, ENABLE_CUSTOM_PROFILE_BOOLEAN_SETTING } from '../constants';
import { setAboutPageBooleanParam, setMyAccountBooleanParam } from './members-area';

export const maybeSetCustomProfileParams = async (editorSDK: EditorSDK, applications: IntegrationApplication[]) => {
  const isCustomProfileEnabled = await experiments.isCustomProfileEnabled();

  if (!isCustomProfileEnabled) {
    return;
  }

  if (applications.find((app) => app.appDefinitionId === MY_ACCOUNT_APP_DEF_ID)) {
    await setMyAccountBooleanParam(editorSDK, ENABLE_CUSTOM_PROFILE_BOOLEAN_SETTING);
  }

  if (applications.find((app) => app.appDefinitionId === MA_APP_IDS.ABOUT.appDefinitionId)) {
    await setAboutPageBooleanParam(editorSDK, ENABLE_CUSTOM_PROFILE_BOOLEAN_SETTING);
  }
};
